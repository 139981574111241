import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="blog-post section">
      <div className="containe has-text-centered">
        <h1>No hay nada aquí</h1>
        <p>Llegaste a una página que no existe</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
