import React from 'react'

import logo from '../img/zubut-icon.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import angel from '../img/social/angel.svg'
import linkedin from '../img/social/linkedin.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <div className="container">
            <div>
              <div>
                <img src={logo} alt="zubut logo" />
                <div>
                  <a
                   title="Aviso de privacidad"
                   href="https://zubut.com/aviso-de-privacidad/"
                   className="mr-2">
                    Aviso de privacidad
                  </a>
                  <a
                   title="Términos y condiciones"
                   href="https://zubut.com/terminos-y-condiciones">
                   Términos y condiciones
                  </a>
                </div>
                <p className="has-text-grey">
                  © 2022 Delivering Geniality SAPI de CV
                </p>
              </div>
              <div className="social">
                <a title="facebook" href="https://www.facebook.com/zubut.delivery/">
                  <img
                    src={facebook}
                    alt="Facebook"
                    target="_blank"
                  />
                </a>                
                <a title="instagram" href="https://www.instagram.com/zubut.delivery/">
                  <img
                    src={instagram}
                    alt="Instagram"
                    target="_blank"
                  />
                </a>
                <a title="linkedin" href="https://www.linkedin.com/company/zubut/">
                  <img                    
                    src={linkedin}
                    alt="LinkedIn"
                    target="_blank"
                  />
                </a>
                <a title="angel" href="https://angel.co/company/zubut-1">
                  <img
                    src={angel}
                    alt="Angel"
                    target="_blank"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
